<template>
  <div class="snippet-form">
    <vx-card class="mb-base no-shadow no-shadow">
      <div class="vx-row mb-6">
        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.hook") }}</label>
          <v-select
            :options="hooks.map((hook) => ({ label: hook.name, value: hook.id }))"
            @input="(hook) => selectHook(hook)"
            v-model="snippet.hook_name"
            :value="snippet.hook_id"
            :placeholder="$t('shops.select_hook')"
            class="w-full"
            :class="{'danger-border-select': errorHook && errors.has('hook')}"
            name="hook"
            v-validate="'required'"
            :danger="errorHook && errors.has('hook')"
            :danger-text="$t('shops.errors.hook_is_required')"
          />
        </div>
      </div>

      <div class="vx-row mb-6">
        <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
          <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
          <vs-input v-if="langRequired(language)"
            v-model="snippet.name"
            v-validate="'required'"
            class="w-full"
            name="snippetName"
            :danger="errorSnippetName && errors.has('errorsnippetName')"
            :danger-text="$t('shops.errors.snippet_name_is_required')" />
          <vs-input v-else v-model="snippet.translations[language].name" class="w-full" :name="`snippetName${language}`" />
        </div>
      </div>

      <div class="vx-row mb-6 mt-10">
        <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t(`shops.description_${language}`) }}</label>
          <vs-input v-if="langRequired(language)" class="w-full" v-model="snippet.description" name="snippetDescription" />
          <vs-input v-else class="w-full" v-model="snippet.translations[language].description" :name="`snippetDescription${language}`" />
        </div>
      </div>

      <div v-for="(language, index) in languages()" :key="index" class="vx-row mb-6">
        <div class="vx-col">
          <label class="vs-input--label">{{ $t(`Code ${language.toUpperCase()}`) }}</label>
          <vue-editor v-if="langRequired(language)" v-model="snippet.code" :use-custom-image-handler="true" @image-added="handleImageAdded" @image-removed="handleImageRemoved"/>
          <vue-editor v-else v-model="snippet.translations[language].code" :use-custom-image-handler="true" @image-added="handleImageAdded" @image-removed="handleImageRemoved"/>
        </div>
      </div>

      <div class="vx-col 1/3">
        <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { VueEditor } from "vue2-editor";
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";

export default {
    name: "SnippetForm",

    props: {
        value: { type: Object, default: null }
    },

    mixins: [ notifications, shopLanguages ],

    components: {
      VueEditor
    },

    data() {
      return {
        errorHook: false,
        errorCode: false,
        errorSnippetName: false,
        snippet: {}
      }
    },

    computed: {
      ...mapGetters("shops/hooks", {
        hooks: "getAll",
        hookId: "getById"
      }),
    },

    methods: {
      ...mapActions("shops/snippets", {
        uploadImage: 'uploadImage',
        removeImage: 'removeImage'
      }),

      async validateAndSubmit() {
        const payload = { ...this.languages().reduce((carry, lang) => {
            carry[lang] = this.snippet.translations[lang]
            return carry
          }, {}), ...this.snippet}

        this.$validator.validateAll().then((result) => {
          if (result && payload.code) {
            this.$emit("submit", payload);
          } else {
            this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.snippet_validation_failed"));
            if (!this.snippet.hook_name) this.errorHook = true;
            if (!this.snippet.name) this.errorSnippetName = true;
            if (!this.snippet.code) this.errorCode = true;
          }
        });
      },

      selectHook(option) {
        this.snippet.hook_id = option === null ? option : option.value;
        this.snippet.hook_name = this.hookId(option.value).name;
      },

      handleImageAdded: async function(file, Editor, cursorLocation, resetUploader) {
        this.$vs.loading();
        try {
          const form = new FormData();
          form.append('image', file)
          const url = await this.uploadImage(form)
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        }
        catch(e) {
          console.warn(e);
        }
        finally {
          this.$vs.loading.close();
        }
      },
      handleImageRemoved: function(file) {
        this.removeImage({path: file})
      }
    },

    created() {
      this.snippet = JSON.parse(JSON.stringify(this.value))
      if(this.snippet.hook){
        this.snippet.hook_name = this.hookId(this.snippet.hook.id).name;
      }
    }
};
</script>

<style></style>
