<template>
    <div>
        <vx-card class="mb-base no-shadow">
            <transition name="fade" mode="out-in">
                <vs-button v-if="showForm" @click="onCreate">{{ $t("shops.back") }}</vs-button>

                <div class="mb-base" v-if="!showForm">
                    <data-table
                        :data="dades"
                        :options="tableOptions"
                        @create="onCreate"
                        @remove="onRemove($event, 'shops.snippet')"
                        @update="loadUpdate"
                        @sort="onSort"
                        @search="onSearch"
                        @change-page="onTablePageChange"
                        @per-page-change="onPerPageChange"
                    />
                </div>
            </transition>

            <transition name="fade" mode="out-in">
                <div class="mb-base mt-10" v-if="showForm">
                    <snippet-form v-model="formData" @submit="submitSnippetForm"/>
                </div>
            </transition>
        </vx-card>
    </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import SnippetForm from "@/modules/Shops/Pages/forms/SnippetForm.vue";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
    name: "Snippets",
    mixins: [ notifications, table_functions, shopLanguages ],
    components: {
        DataTable,
        SnippetForm
    },
    data() {
        return {
            showForm: false,
            formData: {},
            exportPayload: {}
        };
    },
    computed: {
        ...mapGetters("shops/snippets", {
            tableData: "getAll",
            totalPages: "getTotalPages",
            currentPage: "getCurrentPage",
            perPage: "getPerPage",
            snippetById: "getById"
        }),
        dades() {
            return this.tableData.map(this.snippetToTableData);
        },
        tableOptions() {
            return {
                actions: {
                    create: true,
                    update: true,
                    delete: true,
                },
                pagination: {
                    currentPage: this.currentPage,
                    totalPages: this.totalPages,
                    itemsPerPage: this.perPage,
                },
                filters: {
                    dateRange: true,
                },
            };
        },
    },
    methods: {
        ...mapActions("shops/snippets", {load: "load", removeData: "remove"}),
        ...mapActions("shops/hooks", {loadHooks: "load"}),

        snippetToTableData(snippet) {
            return {
                id: snippet.id,
                name: snippet.name,
                description: snippet.description,
                hook: snippet.hook.name,
                created_at: snippet.created_at,
                updated_at: snippet.updated_at,
            };
        },

        async createSnippet(data) {
            this.$vs.loading();
            try {
                const snippet = await this.$store.dispatch(
                    "shops/snippets/create",
                    data
                );
                this.formData = Object.assign({}, this.formData, snippet);
                this.notifySuccess(
                    i18n.t("shops.snippets.title"),
                    `${i18n.t('shops.snippet')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
                );
                await this.load({ page: 1, page_size: this.perPage, sort: "+id" });
            } catch (error) {
                if(error.response && error.response.data.error.code == 23505)
                    this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.unique_hook"));
                else
                    this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_snippet"));
            } finally {
                this.$vs.loading.close();
            }
        },

        async submitSnippetForm(snippet) {
            try {
                if (snippet.id === 0) await this.createSnippet(snippet);
                else await this.update(snippet);
            } catch (error) {
                this.notifyError(i18n.t("shops.errors.failed_to_save_snippet"), error);
            } finally {
                this.showForm = false;
                this.formData = this.default();
                this.$vs.loading.close();
            }
        },

        async update(snippet) {
            try {
                this.$store.dispatch("shops/snippets/update", snippet);
                this.load({ page: this.currentPage, page_size: this.perPage });

                this.formData = this.default();
                this.showForm = false;

                this.notifySuccess(i18n.t("shops.update"),
                    `${i18n.t('shops.snippet')} ${snippet.name} ${i18n.t('shops.has_been_updated_successfully')}`);
            } catch (error) {
                if(error.response && error.response.data.error.code == 23505)
                    this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.unique_hook"));
                else
                    this.notifyError(i18n.t("shops.errors.failed_to_update_snippet"), error)
            }
        },

        loadUpdate(snippet) {
            const translationsDefault = this.translationsDefault(['name', 'description', 'code'])

            this.formData = Object.assign({},this.formData, {
                ...this.snippetById(snippet.id),
                translations: { ...translationsDefault, ...this.snippetById(snippet.id).translations }
            });

            this.$store.dispatch(
                "shops/snippets/assignTranslations",
                this.formData
            );
            this.showForm = true;
        },

        default() {
            return {
                id: 0,
                translations: this.translationsDefault(['name', 'description', 'code']),
                name: "",
                description: "",
                code: "",
            }
        }
    },
    beforeCreate() {
        this.$vs.loading();
    },
    async created() {
      await this.load();
      await this.loadHooks();
      this.$vs.loading.close();
    }
};
</script>
