var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "snippet-form" },
    [
      _c(
        "vx-card",
        { staticClass: "mb-base no-shadow no-shadow" },
        [
          _c("div", { staticClass: "vx-row mb-6" }, [
            _c(
              "div",
              { staticClass: "vx-col w-1/3" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(_vm._s(_vm.$t("shops.hook")))
                ]),
                _c("v-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  class: {
                    "danger-border-select":
                      _vm.errorHook && _vm.errors.has("hook")
                  },
                  attrs: {
                    options: _vm.hooks.map(function(hook) {
                      return { label: hook.name, value: hook.id }
                    }),
                    value: _vm.snippet.hook_id,
                    placeholder: _vm.$t("shops.select_hook"),
                    name: "hook",
                    danger: _vm.errorHook && _vm.errors.has("hook"),
                    "danger-text": _vm.$t("shops.errors.hook_is_required")
                  },
                  on: {
                    input: function(hook) {
                      return _vm.selectHook(hook)
                    }
                  },
                  model: {
                    value: _vm.snippet.hook_name,
                    callback: function($$v) {
                      _vm.$set(_vm.snippet, "hook_name", $$v)
                    },
                    expression: "snippet.hook_name"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "vx-row mb-6" },
            _vm._l(_vm.languages(), function(language, index) {
              return _c(
                "div",
                { key: index, staticClass: "vx-col w-1/5" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
                  ]),
                  _vm.langRequired(language)
                    ? _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: {
                          name: "snippetName",
                          danger:
                            _vm.errorSnippetName &&
                            _vm.errors.has("errorsnippetName"),
                          "danger-text": _vm.$t(
                            "shops.errors.snippet_name_is_required"
                          )
                        },
                        model: {
                          value: _vm.snippet.name,
                          callback: function($$v) {
                            _vm.$set(_vm.snippet, "name", $$v)
                          },
                          expression: "snippet.name"
                        }
                      })
                    : _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { name: "snippetName" + language },
                        model: {
                          value: _vm.snippet.translations[language].name,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.snippet.translations[language],
                              "name",
                              $$v
                            )
                          },
                          expression: "snippet.translations[language].name"
                        }
                      })
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "vx-row mb-6 mt-10" },
            _vm._l(_vm.languages(), function(language, index) {
              return _c(
                "div",
                { key: index, staticClass: "vx-col w-1/3" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(_vm._s(_vm.$t("shops.description_" + language)))
                  ]),
                  _vm.langRequired(language)
                    ? _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { name: "snippetDescription" },
                        model: {
                          value: _vm.snippet.description,
                          callback: function($$v) {
                            _vm.$set(_vm.snippet, "description", $$v)
                          },
                          expression: "snippet.description"
                        }
                      })
                    : _c("vs-input", {
                        staticClass: "w-full",
                        attrs: { name: "snippetDescription" + language },
                        model: {
                          value: _vm.snippet.translations[language].description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.snippet.translations[language],
                              "description",
                              $$v
                            )
                          },
                          expression:
                            "snippet.translations[language].description"
                        }
                      })
                ],
                1
              )
            }),
            0
          ),
          _vm._l(_vm.languages(), function(language, index) {
            return _c("div", { key: index, staticClass: "vx-row mb-6" }, [
              _c(
                "div",
                { staticClass: "vx-col" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(_vm._s(_vm.$t("Code " + language.toUpperCase())))
                  ]),
                  _vm.langRequired(language)
                    ? _c("vue-editor", {
                        attrs: { "use-custom-image-handler": true },
                        on: {
                          "image-added": _vm.handleImageAdded,
                          "image-removed": _vm.handleImageRemoved
                        },
                        model: {
                          value: _vm.snippet.code,
                          callback: function($$v) {
                            _vm.$set(_vm.snippet, "code", $$v)
                          },
                          expression: "snippet.code"
                        }
                      })
                    : _c("vue-editor", {
                        attrs: { "use-custom-image-handler": true },
                        on: {
                          "image-added": _vm.handleImageAdded,
                          "image-removed": _vm.handleImageRemoved
                        },
                        model: {
                          value: _vm.snippet.translations[language].code,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.snippet.translations[language],
                              "code",
                              $$v
                            )
                          },
                          expression: "snippet.translations[language].code"
                        }
                      })
                ],
                1
              )
            ])
          }),
          _c(
            "div",
            { staticClass: "vx-col 1/3" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-base",
                  attrs: { type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.validateAndSubmit()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("shops.save")))]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }